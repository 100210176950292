import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from ".."
import { SUPPORTED_WALLETS } from "../../connectors";

export type ConnectorKey = keyof typeof SUPPORTED_WALLETS;

export interface ConnectorSettingsSlice {
  connectorKey: ConnectorKey | null;
}

const connectorSettingsSlice = createSlice({
  name: "connectorSettings",
  initialState: { connectorKey: null } as ConnectorSettingsSlice,
  reducers: {
    setConnectorSettings: (state, action: PayloadAction<{ connectorKey: ConnectorKey | null }>) => {
      //   console.log('setConnectorSettings', action.payload)
      state.connectorKey = action.payload.connectorKey
    }
  }
})

export const { setConnectorSettings } = connectorSettingsSlice.actions
export default connectorSettingsSlice.reducer

export const selectConnectorSettings = (state: AppState) => state?.connectorSettings
