import { useSelector } from "react-redux";
import { DEFAULT_CHAIN } from "../constants";
import { selectBlockNumbers } from "../state/slices/blockNumbersSlice";
import { useActiveWeb3React } from "./useActiveWeb3React";

export function useBlockNumber(): number | undefined {
    const {chainId} = useActiveWeb3React()
    const numberMap = useSelector(selectBlockNumbers)

    return numberMap[chainId ?? DEFAULT_CHAIN]
}
