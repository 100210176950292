import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import Web3ReactProviderNetwork from "./components/Web3ReactProviderNetwork/Web3ReactProviderNetwork";
import {Provider} from "react-redux";
import {getLibrary} from "./connectors";
import store from "./state";
import BlockNumberUpdater from './state/updaters/blocknumber';
import "./global.css";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ReactProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <BlockNumberUpdater/>
            <BrowserRouter>
              <App />
            </BrowserRouter>
        </Provider>
      </Web3ReactProviderNetwork>
    </Web3ReactProvider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
