import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from ".."

export interface WalletSelectModalSlice {
    modalOpen: boolean,
}

const walletSelectModalSlice = createSlice({
    name: "walletSelectModal",
    initialState: { modalOpen: false } as WalletSelectModalSlice,
    reducers: {
        closeWalletSelectModal: (state) => {
            state.modalOpen = false
        },
        openWalletSelectModal: (state) => {
            // console.log('yoooooooo')
            state.modalOpen = true
        },
        setWalletSelectModal: (state, action: PayloadAction<boolean>) => {
            state.modalOpen = action.payload
        },
    }
})

export const { closeWalletSelectModal, openWalletSelectModal, setWalletSelectModal } = walletSelectModalSlice.actions
export default walletSelectModalSlice.reducer

export const selectWalletSelectModalOpen = (state: AppState) => state?.walletSelectModal?.modalOpen
