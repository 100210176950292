import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Lore from "./pages/Lore";
import {useCallback, useEffect, useState} from "react";
import {useBlockNumber} from "./hooks/useBlockNumber";
import PortalDrawer from "./components/PortalDrawer";
import MobileDrawer from "./components/MobileDrawer";
import NavBar from "./components/NavBar";

function App() {
  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const closeMobileDrawer = useCallback(() => {
    setMobileDrawerOpen(false);
  }, []);

  const x = useBlockNumber()
  console.log({blocknum: x})

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    //TODO: Update meta titles and descriptions below
    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/lore":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <>
      <NavBar setMobileDrawerOpen={setMobileDrawerOpen} />
      {isMobileDrawerOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.5)"
          placement="Top"
          onOutsideClick={closeMobileDrawer}
        >
          <MobileDrawer onClose={closeMobileDrawer} />
        </PortalDrawer>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lore" element={<Lore />} />
      </Routes>
    </>
  );
}
export default App;
