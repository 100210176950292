import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState } from ".."

export interface BlockNumbersSlice {
  [key: number]: number
}

const chainSlice = createSlice({
  name: "blockNumbers",
  initialState: {} as BlockNumbersSlice,
  reducers: {
    setBlockNumber: (state, action: PayloadAction<{ chainId: number, blockNumber: number }>) => {
      //   console.log('setBlockNumber', action.payload)
      const { chainId, blockNumber } = action.payload
      if (!chainId) {
        return
      }
      if (typeof state[chainId] !== 'number') {
        state[chainId] = blockNumber;
      } else {
        state[chainId] = Math.max(
          blockNumber ?? -1,
          state[chainId]
        );
      }
    }
  }
})

export const { setBlockNumber } = chainSlice.actions
export default chainSlice.reducer

export const selectBlockNumbers = (state: AppState) => state?.blockNumbers
