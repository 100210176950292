import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Lore.module.scss";
import icon from "../assets/images/notebook12.svg"

import lore1 from '../assets/images/lore-1.jpg'
import lore2 from '../assets/images/lore-2.jpg'
import lore3 from '../assets/images/lore-3.jpg'

const Lore: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.loreDiv}>
        <div className={styles.loreBodyDiv}>
          <div className={styles.loreContainerDiv}>
            <div className={styles.pDiv}>
              <p className={styles.eXOsStandAlone}>Not many remember the time when planet Aurora was a safe haven for life to thrive in. A colorful and vibrating rock floating in an otherwise hostile and cold universe.</p>
              <p className={styles.eXOsStandAlone}>400 years have passed since the first human expedition arrived on the planet. Drawn by the promise of impossible new materials that could push human civilization to new heights, Earth sent its brightest minds and strongest muscles to turn Aurora into its first intergalactic colony.</p>
              <p className={styles.eXOsStandAlone}>The planet rapidly became a hub for scientific wonders. Thanks to the exploitation of Aurora’s natural resources, the colonizers set a new state of the art for weaponry, medicine and architecture, effectively propelling human knowledge several centuries forward in the timespan of just a few years.</p>
              <p className={styles.eXOsStandAlone}>The colonizers had come thirsty for knowledge and understanding, and Aurora had responded in kind. Unfortunately, those were not the only things humans brought with them.</p>
              <p className={styles.eXOsStandAlone}>Unbridled greed combined with a series of diplomatic failures triggered a war for Aurora’s resources. The new advanced weaponry was ironically first used against the very land where it was developed.</p>
              <img src={lore1} />
              <br />
              <br />
              <p className={styles.eXOsStandAlone}>As a result of the conflict, the planet was ravaged, and the few who managed to survive had to seek shelter beneath the ground, for the dangers unleashed upon Aurora still roamed the surface.</p>
              <p className={styles.eXOsStandAlone}>After a century-long exile, what was left of the survivors formed an unlikely alliance with a newfound species, and working together they succeeded at retaking a small patch of surface.</p>
              <img src={lore2} />
              <br />
              <br />
              <p className={styles.eXOsStandAlone}>The survivors emerged only to discover the planet they knew was no more. Deformed creatures, hostile mechas and entire areas where the air had become unbreathable, Aurora was now the harshest environment any human had attempted to live in. But stubbornness is also part of human nature, and with the aid of the Moonsama they managed to grow that initial patch of land into the city of Nova.</p>
              <p className={styles.eXOsStandAlone}>An era of prosperity followed. Driven by a common goal, species banded together to turn Nova into the home they longed for.</p>
              <p className={styles.eXOsStandAlone}>But “Hard times create strong men, strong men create good times, good times create weak men…”</p>
              <p className={styles.eXOsStandAlone}>The time when Nova’s citizens' worst fear was the creatures roaming the wasteland beyond the city gates quickly evaporated.</p>
              <p className={styles.eXOsStandAlone}>A dramatic crime escalation, an entire district sunk in chaos, or the loss of a loved one... it's not clear what was it that finally pushed Adam, Nova's greatest mind, to seek an ultimate solution to bring peace. What is known is that EXOs were his response.</p>
              <img src={lore3} />
              <br />
              <br />
              <p className={styles.eXOsStandAlone}>In a world filled with wonders, EXOs stand alone as the pinnacle of technological achievements.</p>
              <p className={styles.eXOsStandAlone}>These cyborgs, once designed to keep the streets of Nova safe, are now used by a tyrant to keep his ruthless grip on the city.</p>
              <p className={styles.joinEVAIn1}>Join the ranks of the Resistance in a quest to liberate Nova once and for all.</p>
            </div>
            <div className={styles.cTAsDiv}>
              <button className={styles.button1} onClick={() => window.open('https://opensea.io/collection/exosama-expect-chaos')}>
                <img
                  className={styles.notebookIcon}
                  alt=""
                  src="../sailboat1.svg"
                />
                <b className={styles.rEADWIKIB2}>VIEW COLLECTION</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lore;
