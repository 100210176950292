import MERKLE_JSON from './merkle.json'

const MoonriverIcon = '../assets/images/moonriver_icon.svg'
const MoonbeamIcon = '../assets/images/moonbeam_icon.svg'
const PoopIcon = '../assets/images/poop_token_purple.svg'

export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1285', 10);

export const NetworkContextName = 'NETWORK';

export const DEFAULT_ORDERBOOK_PAGINATION: number = 100;

export const POLLING_INTERVAL = 15000;
export const SUBGRAPH_MAX_BLOCK_DELAY = 2;

export const PINATA_GATEWAY =
  process.env.REACT_APP_PINATA_IPFS_URL ??
  'https://moonsama.mypinata.cloud/ipfs/';

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC = 56,
  EWC = 246,
  VOLTA = 73799,
  MOONRIVER = 1285,
  MOONBEAM = 1284
}

export const numberToChainId: { [chainId: number]: ChainId } = {
  [1]: ChainId.MAINNET,
  [1284]: ChainId.MOONBEAM,
  [1285]: ChainId.MOONRIVER
};

export const DEFAULT_CHAIN = numberToChainId[CHAIN_ID]

export const PERMISSIONED_CHAINS = [DEFAULT_CHAIN]


export const RPC_URLS: { [chainId: number]: string } = {
  [ChainId.MOONRIVER]: 'https://rpc.api.moonriver.moonbeam.network',
  [ChainId.MOONBEAM]: 'https://moonbeam-rpc.moonsama.com'
};

export const NATIVE_TOKEN_SYMBOL: { [chainId: number]: string } = {
  [ChainId.MOONRIVER]: 'MOVR',
  [ChainId.MOONBEAM]: 'GLMR'
};

export const NETWORK_NAME: { [chainId: number]: string } = {
  [ChainId.MOONRIVER]: 'Moonriver',
  [ChainId.MOONBEAM]: 'Moonbeam'
};

export const NETWORK_ICONS: { [chainId: number]: string } = {
  [ChainId.MOONRIVER]: MoonriverIcon,
  [ChainId.MOONBEAM]: MoonbeamIcon
};

export const MARKETPLACE_SUBGRAPH_URLS: { [chainId: number]: string } = {

  [ChainId.MOONRIVER]: 'https://moonriver-subgraph.moonsama.com/subgraphs/name/moonsama/marketplacev8',
  [ChainId.MOONBEAM]: 'https://moonbeam-subgraph.moonsama.com/subgraphs/name/moonsama/marketplacev4'
};

export const MULTICALL_NETWORKS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.VOLTA]: '0xf097d0eAb2dC8B6396a6433978567C443a691815', // latest multicall 2 deployments
  [ChainId.MOONRIVER]: '0x8B60499C8e99d1218Df15ba6e8f0937e1878b86c', // latest multicall 2 deployments
  [ChainId.MOONBEAM]: '0x62614aee098C7a84dC070fF06688F4C35D3868F9'
};

export enum SUPPORTED_CONTRACTS {
  'ENS_RESOLVER' = 'ENS_RESOLVER',
}

export const DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MOONRIVER]: '0x8Bdbb7D542b748D4685fA955C51579956cfC3856',
  [ChainId.MAINNET]: '0x0a39DA505DfB5E0BBc6bd84428281A8ba1bC7753',
};

export const EXO_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MOONRIVER]: '0x5cf141f1BfE0a8dA968978a42ed7F54Cd858aD62',
  [ChainId.MAINNET]: '0xAc5C7493036dE60e63eb81C5e9A440b42f47ebF5',
};

export const EXPLORER_URL: { [chainId in ChainId]?: string } = {
  [ChainId.VOLTA]: 'https://volta-explorer.energyweb.org',
  [ChainId.MOONRIVER]: 'https://moonriver.moonscan.io',
  [ChainId.MOONBEAM]: 'https://blockscout.moonbeam.network',
  [ChainId.MAINNET]: 'https://etherscan.io'
};

export const UTILITY_TOKEN_IMAGE = PoopIcon;

export const UTILITY_TOKEN: { [chainId in ChainId]?: string } = {
  [ChainId.MOONRIVER]: '0x2f82471dccf85d3f6cb498d4e792cfa9e875ab0a',
  [ChainId.MOONBEAM]: '0xfffffffecb45afd30a637967995394cc88c0c194',
}

export const UTILITY_TOKEN_SYMBOL: { [chainId: number]: string } = {
  [ChainId.MOONRIVER]: 'SFT',
  [ChainId.MOONBEAM]: 'POOP'
};


export const PROTOCOL_FEE_BPS = '200';
export const FRACTION_TO_BPS = '10000';

export const STRATEGY_SIMPLE =
  '0xb4c34ccd96d70009be083eaea45c708dff031622381acfcf6e3d07039aca39bb';

export const IPFS_GATEWAYS = [
  'https://moonsama.mypinata.cloud',
  'https://cloudflare-ipfs.com',
  'https://ipfs.io',
];

export const MAX_WIDTH_TO_SHOW_NAVIGATION = 1380;


export interface ContractAddress {
  paymentRegistryAddress: string;
  permissionRegistryAddress: string;
  feeManagerAddress: string;
  paymentProcessorProxyAddress: string;
  showdownAuctionAddress: string;
}

export const CONTRACTS_ADDRESS: Record<number, ContractAddress> = {
  1285: {
    // paymentRegistryAddress: "0x9DF912EfE071873a20Aa2899620925a481f15113",
    // permissionRegistryAddress: "0xFEb996a1A145800fCBA841A7F27b061A79353720",
    // feeManagerAddress: "0x4E98c28B486b73b9bE7403CCd322F4DE4a8394cc",
    // paymentProcessorProxyAddress: "0x5Fc7f39CaDcC6d8441444ac6c47f353BA66A9B69",
    // showdownAuctionAddress: "0xCA9f4D0806bD3910a91d1B93F07e7921735F1DE7",

    // NEW
    paymentRegistryAddress: "0x1D1E5Fa7ff2D428674E5d4C63069ff89C415901d",
    permissionRegistryAddress: "0x32e52Ea931848dF575Aad80Ab79aABA12c1E1AC7",
    feeManagerAddress: "0x8FA7D671e5a4aAC8b1D7043DD47647558BE64E72",
    paymentProcessorProxyAddress: "0x4975662CB55359f7D93B1dc741c68c234EBCc5eD",
    showdownAuctionAddress: "0x0DD9Ec8A1d1F2e8F066CB42461b4B644A5d2c7D1",
  },
};

export const AUCTION_CONFIG = {
  auctionShowdownThreshold: '3600',// 1 hour,
  auctionShowdownExtension: '3600', // 1 hour,
  minBidStepAmount: '1000' // wei
}

export const MERKLE: {leaves: string[], map: {[key: string]: string}, sum: number, root: string, depth: number} = MERKLE_JSON