import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import blockNumbersSlice from './slices/blockNumbersSlice';
import connectorSettingsSlice from './slices/connectorSettingsSlice';
import walletSelectModalSlice from './slices/walletSelectModalSlice';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  blockNumbers: blockNumbersSlice,
  connectorSettings: connectorSettingsSlice,
  walletSelectModal: walletSelectModalSlice,
});

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: []
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
});
export const persistor = persistStore(store)
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
